function About() {
    return (
        <section id="about" className="">
            <section id="hero" className="d-flex">
            </section>
            <div className="card-body">
                <h1 className="mb-4">Learn More About Our Clinic:</h1>
                <div className="card p-4  mb-3 shadow-sm">
                    <h3 className="mb-3">About the Clinic</h3>
                    <div className="d-flex align-items-center">
                        <article className="blog-post d-flex">
                            <div>
                            <p>Al-Razi Medical Centre is committed to providing comprehensive and compassionate healthcare to individuals and families in our community. Our clinic is equipped with state-of-the-art medical technology and staffed by an experienced healthcare professional who is dedicated to your well-being. We offer a welcoming and supportive environment where patients can feel comfortable and confident in the care they receive.</p>
                            <p>At Al-Razi Medical Centre, we believe in a patient-centered approach to healthcare. We take the time to listen to our patients, understand their unique needs, and develop personalized treatment plans. Our services range from routine check-ups and preventive care to specialized treatments and chronic disease management. We are passionate about health education and work closely with our patients to empower them with the knowledge they need to maintain and improve their health.</p>
                            <p>Our clinic values the trust and relationships we build with our patients. We are here to support you and your family through every stage of life, providing the highest standard of care with compassion and respect.</p>
                            </div>
                            {/* <img id='img-contact' src="" alt="Clinic Thumbnail" className="p-2 mr-3" width={400} height={400} /> */}
                       </article>
                    </div>
                </div>
                <div className="card p-4 shadow-sm">
                    <h3 className="mb-3">Doctor Zeyad Dawood</h3>
                    <article className="blog-post">
                        <p>Dr. Zeyad Dawood is a highly experienced and compassionate General Practitioner dedicated to providing the highest quality care to his patients. Dr. Dawood specializes in family medicine, and his priotity is giving the best care to his patients. His approach focuses on holistic care and patient education, ensuring that each individual receives personalized treatment and understands their health conditions thoroughly.</p>
                        <p>He is board-certified and continuously updates his skills through ongoing education and training in the latest medical advancements.</p>
                    </article>
                </div>
            </div>
        </section>
    );
}

export default About;