import React from 'react';

function Footer() {
    return (
        <footer id="footer" className="footer p-3">
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md-4 mb-3">
                        <h5>Al Razi Medical</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/#" className="nav-link p-0 text-body-secondary">Home</a></li>
                            <li className="nav-item mb-2"><a href="/services" className="nav-link p-0 text-body-secondary">Services</a></li>
                            <li className="nav-item mb-2"><a href="/services" className="nav-link p-0 text-body-secondary">Repeat Prescriptions</a></li>
                            <li className="nav-item mb-2"><a href="/about" className="nav-link p-0 text-body-secondary">About</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-4 mb-3">
                        <h5>Services</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/services/#dermatology" className="nav-link p-0 text-body-secondary">Dermatology</a></li>
                            <li className="nav-item mb-2"><a href="/services/#bloods" className="nav-link p-0 text-body-secondary">Bloods</a></li>
                            <li className="nav-item mb-2"><a href="/services/#sti-screening" className="nav-link p-0 text-body-secondary">STI screening</a></li>
                            <li className="nav-item mb-2"><a href="/services/#cosmetics" className="nav-link p-0 text-body-secondary">Cosmetics</a></li>
                            <li className="nav-item mb-2"><a href="/services/#paediatrics" className="nav-link p-0 text-body-secondary">Paediatrics</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2 offset-md-1 mb-3">
                        <div>
                            <p>Opening Hours</p>
                            <p>Saturdays 9:00am - 5:00pm</p>
                            <p>Tel No: 089 400 6599</p>
                            <p>Tel No: 089 228 3338</p>
                            <p>Email: info@alrazimedicalcentre.com</p>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-center">
                    <p>&copy; 2024 Al Razi Medical Centre. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
