import './Services.css';
import React, { useState } from 'react';


function Services() {
    const [dropdownState, setDropdownState] = useState({});

    const toggleDropdown = (id) => {
        setDropdownState((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    return (
        <div className="container my-3 py-2">
            <article className="blog-post">
                <h2 className="display-5 link-body-emphasis mb-1">Our Services</h2>
                <p>Our clinic is run by an experienced doctor who is registered in General Practice and provides a variety of medical services including:</p>
                <hr />
                <p className='text-center'>Learn more about them here: </p>
                <div className='card mb-2 border-bottom bg-light' id='gp'>
                    <div className="dropdown-info-box" onClick={() => toggleDropdown(1)}>
                        <button className="btn">
                            {dropdownState[1] ? '^ GP/Medical Consultation' : 'GP/Medical Consultation'}
                        </button>
                        {dropdownState[1] && (
                            <div className="info-box mt-3 p-2">
                                <p>At Alcare Medical Centre, we prioritize your health and well-being with comprehensive
                                    GP consultations tailored to your needs. </p><p>Our experienced doctor is
                                        dedicated to providing personalized care, whether you need routine check-ups,
                                        chronic disease management, or immediate medical advice. </p><p>
                                    With a focus on preventive care and patient education,
                                    we ensure you receive the highest quality of care in a friendly and welcoming environment.
                                    Schedule your consultation today and take the first step towards better health with Alcare Medical Centre.</p>
                                <p>Email us for any more enquiries at info@alrazimedicalcentre.com</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='card mb-2 ' id='dermatologys'>
                    <div className="dropdown-info-box" onClick={() => toggleDropdown(2)}>
                        <button className="btn ">
                            {dropdownState[2] ? '^ Dermatology' : 'Dermatology '}
                        </button>
                        {dropdownState[2] && (
                            <div id="infoBox" className="info-box mt-3 p-2">
                                <p>With a dermatology degree our doctor can address any concerns you have with your skin.</p>
                                <p>Our dermatology services offer comprehensive skin care for conditions such as acne, eczema, and psoriasis.</p>
                                <p>Our specialists use the latest treatments and technology to provide personalized care for each patient.</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='card mb-2 bg-light' id='prescriptions'>
                    <div className="dropdown-info-box" onClick={() => toggleDropdown(3)}>
                        <button className="btn ">
                            {dropdownState[3] ? '^ Cosmetics' : 'Cosmetics '}
                        </button>
                        {dropdownState[3] && (
                            <div className="info-box mt-3 p-2">
                                <p>Our cosmetic services include non-surgical treatments such as Botox, and dermal fillers.</p>
                                <p> We aim to enhance your natural beauty and boost your confidence with safe and effective procedures.</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='card mb-2' id='paediatrics'>
                    <div className="dropdown-info-box" onClick={() => toggleDropdown(4)}>
                        <button className="btn ">
                            {dropdownState[4] ? '^ Paediatrics' : 'Paediatrics '}
                        </button>
                        {dropdownState[4] && (
                            <div className="info-box mt-3 p-2">
                                <p>Our paediatric services focus on the health and well-being of infants, children, and adolescents. Our team provides compassionate and comprehensive care for your child.</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='card mb-2 bg-light' id='bloods'>
                    <div className="dropdown-info-box" onClick={() => toggleDropdown(5)}>
                        <button className="btn ">
                            {dropdownState[5] ? '^ Bloods' : 'Bloods '}
                        </button>
                        {dropdownState[5] && (
                            <div className="info-box mt-3 p-2">
                                <p>We offer a range of blood tests to diagnose and monitor various health conditions.</p>
                                <p> Our bloods services ensure quick and accurate results, helping your doctor provide the best care possible.</p>
                                <p>The blood tests could include:</p>
                                <ul>
                                    <li> Full blood count inc: Heamoglobin, White cells count etc.</li>
                                    <li> Cholesterol and lipids</li>
                                    <li>Glucose </li>
                                    <li>TFT – thyroid function test</li>
                                    <li> Renal or kidney function</li>
                                </ul>

                            </div>
                        )}
                    </div>
                </div>
                <div className='card mb-2' id='sti-screening'>
                    <div className="dropdown-info-box" onClick={() => toggleDropdown(6)}>
                        <button className="btn ">
                            {dropdownState[6] ? '^ STI screening' : 'STI screening '}
                        </button>
                        {dropdownState[6] && (
                            <div className="info-box mt-3 p-2">
                                <p>Our STI screening services are confidential and comprehensive. </p>
                                <p>We offer testing and treatment for a range of sexually transmitted infections, ensuring your privacy and health are our top priorities.</p>
                            </div>
                        )}
                    </div>
                </div>
            </article>
        </div>
    );
}

export default Services;