import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import ContactForm from './components/ContactForm';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
// import RP from './components/RP';
import PageNotFound from './components/PageNotFound';
import Pricing from './components/Pricing';

function App() {
  return (
    <div id="app" className="" >
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/repeat-prescriptions" element={<RP />} /> */}
          {/* <Route path="/contact" element={<ContactForm />} /> */}
          <Route path="/services" element={<Services />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
