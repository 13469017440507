import React from 'react';

function Pricing() {

    return (
        <div className="container my-2 py-2">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Service</th>
                        <th scope="col">Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="col">0</th>
                        <th scope="col">Telephone Consultation</th>
                        <th scope="col">€55</th>
                    </tr>
                    <tr>
                        <th scope="col">1</th>
                        <th scope="col">GP Consultation</th>
                        <th scope="col">€55</th>
                    </tr>
                    <tr>
                        <th scope="col">2</th>
                        <th scope="col">Dermatology Consultation</th>
                        <th scope="col">€65</th>
                    </tr>
                    <tr>
                        <th scope="col">3</th>
                        <th scope="col">Cosmetics</th>
                        <th scope="col">*On Consultation</th>
                    </tr>
                    <tr>
                        <th scope="col">4</th>
                        <th scope="col">Bloods</th>
                        <th scope="col">€80</th>
                    </tr>
                    <tr>
                        <th scope="col">5</th>
                        <th scope="col">Minor Surgery</th>
                        <th scope="col">*Based on procedure</th>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Pricing;
