import React, { useState, useEffect } from 'react';
import './Carousel.css';
import picture1 from '../dawson-street.jpg';
import picture2 from '../dermacare.jpg';
import picture3 from '../hospbg.jpg';

const images = [
  picture1,
  picture2,
  picture3,
];

const description = [
  <div className="carousel-caption text-end">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
    </svg>
    <h1>Located in the Heart of Dublin</h1>
    <p>You can find us in 32 Nassau St, right by Trinity College Dublin</p>
    <p><a className="btn btn-lg btn-primary" href="/#map">Find Us</a></p>
  </div>,
  <div className="carousel-caption text-end">
    <h1>General Practitioner Consultation</h1>
    <p>Book an appointment to be seen by the doctor. You deserve excellent care by experienced professionals!</p>
    <p><a className="btn btn-lg btn-primary" href="https://alrazimedicalcentre.setmore.com">Book An Appointment</a></p>
  </div>,
  <div className="carousel-caption text-end">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5"/>
</svg>
  <h1>24/7 Telephone Consultation Service</h1>
  <p>Make sure to book an appointment in advance to be seen by the doctor. Please be advised that Telephone</p>
  <p><a className="btn btn-lg btn-primary" href="https://alrazimedicalcentre.setmore.com">Book An Appointment</a></p>
</div>
]

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 9000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="carousel slide">
      <div className="carousel-inner">
        {images.map((image, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
          >
            <img className='d-block carousel-image' src={image} alt={`Slide ${index + 1}`} />
            <div className="carousel-caption d-none d-md-block text-start">
              <div>{description[index]}</div>
            </div>
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" onClick={handlePrev}>
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </button>
      <button className="carousel-control-next" onClick={handleNext}>
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
