import React, { useRef, useEffect } from 'react';
import './Nav.css';

function Nav() {
    const ref = useRef();

    function clicked() {
        console.log("Clicked!");
        const refer = ref.current;
        if (refer.className === "hide") {
            refer.className = "show bg-light";
        } else {
            refer.className = "hide";
        }
    }

    useEffect(() => {
        function handleResize() {
            const refer = ref.current;
            if (window.innerWidth > 990) {
                refer.className = "show";
            } else {
                refer.className = "hide"
            }
        }

        window.addEventListener('resize', handleResize);

        // Initial check to set the correct class based on the current window size
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id="nav" className="bg-dark">
            <div className="text-light d-flex">
                <p className="flex-end">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                    </svg> 089 400 6599
                </p>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light p-3 border-top" aria-label="Ninth navbar example">
                <div id="con-nav" className="container-xl">
                    <a href="/" className="navbar-brand d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none">
                        <img className="bi me-2" width="50" height="50" src="../logo.png" alt="site logo" />
                        <span className="fs-4"> Al-Razi Medical Centre</span>
                    </a>
                    <div id="navbar" ref={ref} className="show">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/#">HOME</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/pricing">PRICING</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/services">SERVICES</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/about">ABOUT</a>
                            </li>
                            <li className="btn btn-primary">
                                <a id="appointment" className="active" aria-current="page" href="https://alrazimedicalcentre.setmore.com">BOOK AN APPOINTMENT</a>
                            </li>
                        </ul>
                    </div>
                    <button id='menu-btn' className='btn mb-2' onClick={clicked}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                        </svg>
                    </button>
                </div>
            </nav>
        </div>
    );
}

export default Nav;
